import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import CategorySlider from './../CategoryPage/CategorySlider';
import NewsList from './NewsList';
import api from '../../api';

const CategoryNewsList = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [stories, setStories] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchNewsForCategory = useCallback(async (categoryId) => {
    if (!categoryId) return;
    try {
      const response = await api.get(`/api/v1/stories/last/?category=${categoryId}`);
      setStories(response.data.results);
      setHasMore(response.data.next !== null);
    } catch (error) {
      console.error('Ошибка при загрузке новостей:', error);
    }
  }, []);

  useEffect(() => {
    api.get('/api/v1/categories/')
      .then(response => {
        setCategories(response.data);
        if (!id && response.data.length > 0) {
          const defaultCategory = response.data[0];
          setSelectedCategory(defaultCategory);
          fetchNewsForCategory(defaultCategory.id);
        }
      })
      .catch(error => console.error('Ошибка при загрузке категорий:', error));
  }, [id, fetchNewsForCategory]);

  const handleSelectCategory = (category) => {
    if (category) {
      setSelectedCategory(category);
      fetchNewsForCategory(category.id);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 0 }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', mb: 0 }}>
        {selectedCategory && (
          <Typography
            variant="h5"
            fontWeight={700}
            gutterBottom
            sx={{
              position: 'relative',
              display: 'inline-block',
              '&:after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '4px',
                backgroundColor: '#d3d3d3',
                position: 'absolute',
                bottom: '-4px',
                left: 0,
              },
              '&:before': {
                content: '""',
                display: 'inline-block',
                width: '5px',
                height: '4px',
                backgroundColor: '#d3d3d3',
                position: 'absolute',
                bottom: '-4px',
                right: '-12px',
              },
            }}
          >
            {selectedCategory.name || 'Категория не выбрана'}
          </Typography>
        )}

        <CategorySlider
          categories={categories}
          onSelectCategory={handleSelectCategory}
          selectedCategory={selectedCategory}
          localMode={true}
        />

        <NewsList
          stories={stories}
          selectedCategory={selectedCategory}
          fetchNewsForCategory={fetchNewsForCategory}
          hasMore={hasMore}
        />
      </Box>
    </Container>
  );
};

export default CategoryNewsList;
