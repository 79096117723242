import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import NewsCard from '../NewsCard'; // Предполагается, что у вас есть этот компонент
import { useTheme, useMediaQuery } from '@mui/material';
import api from '../../api'; // Подключаем ваш API

const EditorialPicks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [stories, setStories] = useState([]); // Состояние для хранения сюжетов
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [page, setPage] = useState(1); // Состояние для пагинации

  // Функция для загрузки данных
  const fetchStories = async (page) => {
    setLoading(true);
    try {
      let url = `/api/v1/stories/?page=${page}&page_size=3`;
      const response = await api.get(url);
      const fetchedStories = response.data.results;

      setStories((prevStories) => [...prevStories, ...fetchedStories]); // Добавляем новые сюжеты к уже существующим
    } catch (error) {
      console.error('Ошибка при загрузке сюжетов:', error);
    }
    setLoading(false);
  };

  // Загрузка первых 3 новостей при монтировании компонента
  useEffect(() => {
    fetchStories(page);
  }, [page]);

  // Функция для загрузки следующих 3 сюжетов
  const loadMoreStories = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Box>
      <Container>
        <Box
          sx={{
            display: isMobile ? 'flex' : 'block',
            justifyContent: isMobile ? 'center' : 'initial',
            alignItems: isMobile ? 'center' : 'initial',
            textAlign: isMobile ? 'center' : 'left',
            position: 'relative',
            paddingBottom: '8px',
            marginBottom: '20px',
          }}
        >
          <Typography
            variant="h6"
            fontWeight={700}
            gutterBottom
            sx={{
              position: 'relative',
              display: 'inline-block',
              '&:after': {
                content: '""',
                display: 'inline-block',
                width: '100%',
                height: '4px',
                backgroundColor: '#d3d3d3',
                position: 'absolute',
                bottom: '-4px',
                left: 0,
              },
              '&:before': {
                content: '""',
                display: 'inline-block',
                width: '5px',
                height: '4px',
                backgroundColor: '#d3d3d3',
                position: 'absolute',
                bottom: '-4px',
                right: '-12px',
              },
            }}
          >
            Выбор редакции
          </Typography>
        </Box>
      </Container>

      <Box>
        {loading && stories.length === 0 ? (
          <Grid container spacing={2}>
            {Array.from(new Array(3)).map((_, index) => (
              <Grid item xs={12} key={index}>
                <Skeleton variant="rectangular" width="100%" height={320} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2} direction="column">
            {stories.length > 0 ? (
              stories.slice(0, 3).map((story) => (
                <Grid item xs={12} key={story.id}>
                  <Link to={`/story/${story.id}`} style={{ textDecoration: 'none' }}>
                    <NewsCard
                      item={{
                        title: story.news_article.title || 'Название отсутствует',
                        summary: story.news_article.summary || 'Краткое описание отсутствует',
                        description: story.news_article.description || 'Описание отсутствует',
                        main_image: story.news_article.main_image || 'https://via.placeholder.com/300x200',
                      }}
                      time={story.news_article.publication_at || 'Дата не указана'}
                      theme={theme}
                      isLink
                    />
                  </Link>
                </Grid>
              ))
            ) : (
              <Typography variant="h6" align="center">
                Нет новостей для отображения.
              </Typography>
            )}
          </Grid>
        )}

        {/* Кнопка для загрузки дополнительных сюжетов */}
        {!loading && stories.length < 3 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" onClick={loadMoreStories}>
              Показать еще
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EditorialPicks;
