import React from 'react';
import DynamicTimeDisplay from '../DynamicTimeDisplay';
import { Box, Typography, Avatar, Grid } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';



const NewsHeader = ({ source, creationDate, title }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Проверка для ширины экрана менее 600px

  return (
    <Box>
      <Grid container padding={1} spacing={0}>
        {/* 1-я строка: Логотип и название источника */}
        <Grid item xs={12}>
          <Box
            display="flex"
            
          >
            <Avatar
              src={source.favicon}
              alt="News Source"
              sx={{
                width: isMobile ? 15 : 15, // Изменение размера аватара для мобильных
                height: isMobile ? 15 : 15,
                border: '1px solid gray',
                marginRight: isMobile ? '5px' : '5px',
              }}
            />
            <Typography
              variant="body1"
              sx={{
                fontSize: isMobile ? '12px' : '14px',
                fontWeight: 600
              }}
            >
              {source.name}
            </Typography>
          </Box>
        </Grid>

        {/* 2-я строка: Заголовок */}
        <Grid item xs={12}>
          <Typography
            variant="h1"
            sx={{
              fontSize: isMobile ? '20px' : '24px',
              fontWeight: 700,              
              paddingTop: isMobile ? '5px' : '5px',
            }}
          >
            {title}
          </Typography>
        </Grid>

        {/* 3-я строка: Время сюжета */}
        <Grid item xs={12}>
          <Box
            sx={{              
              paddingTop: isMobile ? '0px' : '0px',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: '#adadad',
                fontWeight: 700,
                fontSize: isMobile ? '10px' : '12px', // Уменьшение шрифта на мобильных
              }}
            >
              <DynamicTimeDisplay creationDate={creationDate} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsHeader;