import React, { useEffect, useState } from 'react';
import { Box} from '@mui/material';
import api from '../../api';
import RelevantItem from './RelevantItem';
import TitleWithLineAndDot from '../TitleWithLineAndDot';

const RelevantSideBar = ({ category }) => {
  const [relatedStories, setRelatedStories] = useState([]);

  useEffect(() => {
    const fetchRelatedStories = async () => {
      try {
        let response;
        if (category) {
          console.log('Fetching related stories for category ID:', category);
          response = await api.get(`/api/v1/stories/?category=${category}&page_size=5`);
        } else {
          console.log('Fetching general stories');
          response = await api.get('/api/v1/stories/?page_size=5');
        }
  
        const stories = response.data.results;
        console.log('Related Stories IDs:', stories.map(story => story.id)); // Проверка ID сюжетов
        setRelatedStories(stories);
      } catch (error) {
        console.error('Ошибка при загрузке сюжетов:', error);
      }
    };
  
    fetchRelatedStories();
  }, [category]);
  

  return (
    <Box sx={{ width: '100%', paddingTop: 2 }}>
      <Box paddingTop={2} paddingLeft={1}>
      <TitleWithLineAndDot
        title="Новости из категории" // Передаем название заголовка
        fontSize="20px" // Настраиваем размер шрифта
        lineHeight={6} // Толщина линии
        fontWeight={700} // Толщина шрифта
      />
      </Box>
      <Box paddingTop={2}>
      {relatedStories.map((story) => (
  <RelevantItem key={story.id} story={story} />
))}

      </Box>
    </Box>
  );
};

export default RelevantSideBar;
