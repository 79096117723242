import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import CategorySlider from '../components/CategoryPage/CategorySlider';
import NewsHeader from '../components/NewsDetailPage/NewsHeader';
import NewsMainContent from '../components/NewsDetailPage/NewsMainContent';
import RelatedArticles from '../components/NewsDetailPage/RelatedArticles';
import RelevantSideBar from '../components/RelevantSideBar/RelevantSideBar';
import api from '../api';

const NewsDetailPage = () => {
  const { id } = useParams(); // Получаем ID сюжета из URL
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [imagesForSlider, setImagesForSlider] = useState([]);
  const [hasTracked, setHasTracked] = useState(false); // Добавляем флаг отслеживания

  // Загрузка категорий
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/api/v1/categories/');
        setCategories(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
      }
    };

    fetchCategories();
  }, []);

  // Функция отслеживания активности пользователя
  const trackUserActivity = async (storyId) => {
    try {
      await api.post('/api/v1/user-activity/track_activity/', { story_id: storyId });
      console.log('Активность пользователя зафиксирована');
    } catch (error) {
      console.error('Ошибка при фиксации активности пользователя:', error);
    }
  };

  // Загрузка сюжета по ID
  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await api.get(`/api/v1/story-detail/${id}/`);
        setStory(response.data);
        setSelectedArticle(response.data.news_articles[0]);

        const images = [...response.data.main_images];
        setImagesForSlider(images);

        if (response.data.news_articles[0].category) {
          setSelectedCategory(response.data.news_articles[0].category);
        }

        // Отслеживаем активность пользователя только один раз
        if (response.data && !hasTracked) {
          trackUserActivity(response.data.id);
          setHasTracked(true);  // Устанавливаем флаг, чтобы предотвратить повторный вызов
        }
      } catch (error) {
        console.error('Ошибка при загрузке сюжета:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchStory();
    }
  }, [id, hasTracked]);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Если идет загрузка
  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', marginTop: '40px' }}>
        <CircularProgress />
      </Container>
    );
  }

  // Если сюжет не найден
  if (!story) {
    return <Container>Сюжет не найден</Container>;
  }
  return (
    <Container>
      <Box sx={{ marginTop: '40px', marginBottom: '20px' }}>
        {categories && categories.length > 0 && (
          <CategorySlider
            categories={categories}
            onSelectCategory={handleSelectCategory}
            selectedCategory={selectedCategory}
          />
        )}
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          {selectedArticle && (
            <>
              <NewsHeader
                source={selectedArticle.source}
                creationDate={story.creation_at}
                title={selectedArticle.title}
              />
              <Box mt={1}>
                <NewsMainContent
                  mainImages={imagesForSlider}
                  text={selectedArticle.summary}
                  url={selectedArticle.url}
                />
              </Box>
              <Box mt={4}>
                <RelatedArticles
                  articles={story.news_articles}
                  onArticleClick={handleArticleClick}
                  mainSource={selectedArticle.source.name}
                  selectedArticle={selectedArticle}
                />
              </Box>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          <Box sx={{ position: 'sticky', top: '100px' }}>
            <RelevantSideBar category={story.category} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewsDetailPage;
