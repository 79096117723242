import React, { useState } from 'react'; 
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './ThemeContext'; 
import Header from './components/Header/Header';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ProfilePage from './components/Profile/ProfilePage';
import CategoriesPage from './pages/CategoryPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import DataProcessingPolicyPage from './pages/DataProcessingPolicyPage';
import NewsDetailPage from './pages/NewsDetailPage';
import NotFound from './components/NotFound';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './components/PrivateRoute'; 
import Box from '@mui/material/Box';
function App() {
  const [user, setUser] = useState(null); // Глобальное состояние пользователя

  return (    
    <ThemeProvider>
      <Router>
        <Box  sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: 'background.default', color: 'text.primary' }}>
          <Header user={user} setUser={setUser} /> {/* Передаем user и setUser в Header */}
          <Box className="App-content" sx={{ flex: 1 }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route 
                path="/profile" 
                element={<PrivateRoute element={<ProfilePage user={user} setUser={setUser} />} />} 
              /> {/* Передаем user и setUser в ProfilePage */}
              <Route path="/category/:id" element={<CategoriesPage />} />
              <Route path="/cookie-policy" element={<CookiePolicyPage />} />
              <Route path="/data-processing-policy" element={<DataProcessingPolicyPage />} />
              <Route path="/story/:id" element={<NewsDetailPage />} />            
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
          <Footer />
          <ScrollToTop />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;

