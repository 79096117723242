import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: 'https://zn.by/',
  withCredentials: true,
});


api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {


      originalRequest._retry = true;

      try {
        const refreshToken = Cookies.get('refresh-token');
        if (!refreshToken) {
          return Promise.reject(error);
        }
       
        return api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export const registerUser = async ({ username, email, password1, password2, first_name, last_name }) => {
  const response = await api.post('/api/auth/registration/', {
    username,
    email,
    password1,
    password2,
    first_name,
    last_name,
  });
  return response.data;
};

export const loginUser = async ({ username, password }) => {
  try {
    // Отправляем запрос на API для авторизации
    const response = await api.post('/api/auth/login/', { username, password });

    // Если вход успешен, возвращаем данные пользователя
    return response.data;
  } catch (error) {
    // Если сервер вернул ошибку 400 Bad Request, выводим сообщение от API
    if (error.response && error.response.status === 400 && error.response.data.non_field_errors) {
      throw new Error(error.response.data.non_field_errors[0]); // Выводим сообщение об ошибке с сервера
    } else {
      // Обработка других ошибок (например, ошибка сети)
      throw new Error('Произошла ошибка при подключении к серверу');
    }
  }
};

export const resetPassword = async (email) => {
  const response = await api.post('/api/auth/password/reset/', { email });
  return response.data;
};

export const logoutUser = async () => {
  const response = await api.post('/api/auth/logout/');
  return response.data;
};

export const changePassword = async ({ new_password1, new_password2 }) => {
  const response = await api.post('/api/auth/password/change/', { new_password1, new_password2 });
  return response.data;
};

export const refreshToken = async (refresh) => {
  const response = await api.post('/api/auth/token/refresh/', { refresh });
  return response.data;
};

export const getUser = async () => {
  const response = await api.get('/api/auth/user/');
  return response.data;
};

export const updateUser = async (userData) => {
  const response = await api.patch('/api/auth/user/', userData);
  return response.data;
};

export const verifyToken = async (token) => {
  await axios.post('/api/token/verify/', { token });
};


export const authenticateUser = async (data) => {
  try {
    const response = await api.post('/login/', data);
    return response.data; // Успешная аутентификация, возвращаем данные пользователя
  } catch (error) {
    if (error.response && error.response.data) {
      // Если от API пришёл объект с ошибками, возвращаем его
      throw error.response.data; 
    } else {
      // Если произошла ошибка на уровне сети или сервера, выводим общее сообщение
      throw new Error('Произошла ошибка при подключении к серверу');
    }
  }
};


export default api;
