import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, List, ListItem, Avatar, Typography, Button } from '@mui/material';
import DynamicTimeDisplay from '../DynamicTimeDisplay';

const NewsList = ({ stories, selectedCategory, fetchNewsForCategory, hasMore }) => {
  const [visibleCount, setVisibleCount] = useState(5); 
  const theme = useTheme();
 
  useEffect(() => {
    setVisibleCount(5); 
    fetchNewsForCategory(selectedCategory); 
  }, [selectedCategory, fetchNewsForCategory]);


  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 5); 
  };

  return (
    <Box>
  <List sx={{ paddingTop: 2 }}>
    {stories.slice(0, visibleCount).map((story) => (
    <ListItem
    key={story.id}
    alignItems="flex-start"
    component={Link}
    to={`/story/${story.id}`}
    sx={{
      textDecoration: 'none',
      color: 'inherit',
      borderRadius: '10px',
      padding: '5px 5px', 
      marginBottom: '2px',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', // Различный эффект наведения в зависимости от темы
      },
    }}
  >
  
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {story.news_article.source && (
              <Avatar
                alt={story.news_article.source.name}
                src={story.news_article.source.favicon}
                sx={{
                  width: 20,
                  height: 20,
                  border: '1px solid #ccc',
                  marginRight: '8px',
                }}
              />
            )}
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              sx={{ fontWeight: 500, marginRight: '8px', fontSize: '16px' }}
            >
              {story.news_article.source.name}
            </Typography>
            <Typography component="span" variant="body2" color="textSecondary">
              {story.creation_at ? (
                <DynamicTimeDisplay creationDate={story.creation_at} />
              ) : (
                'Время не указано'
              )}
            </Typography>
          </Box>

          <Typography
            variant="body1"
            fontWeight={600}
            fontSize={14}
            color="textPrimary"
            sx={{ lineHeight: '1.2' }} 
          >
            {story.title || 'Заголовок не указан'}
          </Typography>
        </Box>
      </ListItem>
    ))}
  </List>

  {visibleCount < stories.length && (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
      <Button
        onClick={handleShowMore}
        variant="text"
        sx={{
          fontWeight: 'bold', 
        }}
      >
        +5 показать
      </Button>
    </Box>
  )}
</Box>

  );
};


export default NewsList;
