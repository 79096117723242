import React from 'react';
import { Card } from '@mui/material';
import NewsImage from './NewsCard/NewsImage';
import NewsContent from './NewsCard/NewsContent';
import NewsFooter from './NewsCard/NewsFooter';
import { timeAgo } from '../utils/timeAgoUtils';

const NewsCard = ({ item, time, theme }) => (
  <Card
  sx={{
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    bgcolor: `${theme.palette.mode === 'dark' ? '#58585854' : '#ebebebff'}`,
    border: `1px solid ${theme.palette.mode === 'dark' ? '#b0b0b054' : 'transparent'}`,
    position: 'relative',
    transition: 'box-shadow 0.3s ease-in-out', // Плавный переход для тени
    '&:hover': {
      boxShadow: theme.palette.mode === 'dark'
        ? '0px 4px 12px rgba(180, 180, 180, 0.774)'
        : '0px 4px 12px rgba(0, 0, 0, 0.39)',
    },
    // Ограничение ширины для разных экранов
    width: '100%', // По умолчанию занимает 100% доступного пространства
    maxWidth: '350px', // Максимальная ширина карточки для больших экранов
    '@media (max-width: 900px)': {
      maxWidth: '300px', // На экранах до 900px уменьшаем ширину до 300px
      marginLeft: 0, // Прижимаем к левому краю
      marginRight: 'auto', // Чтобы карточка не центрировалась, правый край будет свободен
    },
    '@media (max-width: 600px)': {
      maxWidth: '300px', // Ограничиваем ширину до 300px на экранах 600-300px
      width: '100%', // На экранах меньше 600px карточки занимают всю ширину, но ограничены 300px
      marginLeft: 'auto', // Центрируем карточку
      marginRight: 'auto',
    },
    '@media (max-width: 300px)': {
      maxWidth: '100%', // На экранах менее 300px карточка растягивается на всю ширину
      marginLeft: 0, // Прижимаем к левому краю
      marginRight: 0,
    },
  }}
>
    <NewsImage image={item.main_image} category={item.category} />
    <NewsContent
      title={item.title}
      summary={item.summary}
      description={item.description}
      text={item.text}
    />
    <NewsFooter      
      publicationDate={timeAgo(time)}
    />
  </Card>
);

export default NewsCard;
