import React  from 'react';
import { Box, Grid, Button  } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ImageSliderModal from './ImageSliderModal.js';

function addSpaceBeforeLinks(text) {    
    const regex = /(<a\s*href=["'][^>]+>)/g;    
    const updatedText = text.replace(regex, ' $1 ');
    return updatedText;
}


const NewsMainContent = ({ mainImages, text, url }) => { 


  const updatedText = addSpaceBeforeLinks(text);  
  
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ alignSelf: 'flex-start', width: '100%' }}>
            <Box sx={{ width: '100%', fontSize: 14, fontWeight: 500, textAlign: 'justify' }}>
              <span dangerouslySetInnerHTML={{ __html: updatedText }} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ mb: 1 }}>
            <Button
              variant="contained"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                fontSize: '0.9rem',
                textTransform: 'none',
                padding: '0px 10px',
                borderRadius: '5px',
                fontWeight: '700',
                backgroundColor: '#c7c7c783',
                color: '#000000',
                '&:hover': {
                  backgroundColor: '#a8a8a883',
                },
              }}
            >
              Читать в первоисточнике
              <Box ml={1} mt={1} color={'#00000042'}>
                <LaunchIcon sx={{ fontSize: '20px' }} />
              </Box>
            </Button>
          </Box>
        </Grid>

        {/* Отображение всех изображений в слайдере */}
        <Grid item xs={12}>
          {mainImages && mainImages.length > 0 && (
            <ImageSliderModal mainImages={mainImages} /> 
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsMainContent;