import React, { useState } from 'react';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ImageSliderModal = ({ mainImages }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClickOpen = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setOpen(true);
  };  

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % mainImages.length;
    setSelectedImage(mainImages[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handlePrevious = () => {
    const newIndex = (currentIndex - 1 + mainImages.length) % mainImages.length;
    setSelectedImage(mainImages[newIndex]);
    setCurrentIndex(newIndex);
  };


  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        {mainImages.slice(0, 3).map((image, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10px',
              cursor: 'pointer',
              width: '300px',
              height: '180px',
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              position: 'relative',
            }}
            onClick={() => handleClickOpen(image, index)}
          >
            {index === 2 && mainImages.length > 3 && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  padding: '10px',
                }}
              >
                +Еще фото
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {/* Модальное окно для увеличенного изображения */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            padding: '0',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff',
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Стрелки для перехода между изображениями */}
          <IconButton
            onClick={handlePrevious}
            sx={{
              position: 'absolute',
              left: '20px',
              color: 'white',
              zIndex: 2,
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <img
            src={selectedImage}
            alt="Selected Story" // Удалите слово "Image" или используйте более подходящее описание
            style={{ width: '100%', height: 'auto', maxHeight: '80vh', borderRadius: '8px' }}
          />

          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: '20px',
              color: 'white',
              zIndex: 2,
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>

          {/* Миниатюры под изображением */}
          <Box sx={{ position: 'absolute', bottom: '10px', display: 'flex', gap: '5px' }}>
            {mainImages.map((img, idx) => (
              <Box
                key={idx}
                onClick={() => {
                  setSelectedImage(img);
                  setCurrentIndex(idx);
                }}
                sx={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '4px',
                  backgroundImage: `url(${img})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  border: idx === currentIndex ? '2px solid white' : '2px solid transparent',
                  cursor: 'pointer',
                }}
              />
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageSliderModal;
