import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { TextField, Button, Box, Typography, CircularProgress, Modal } from '@mui/material';
import ForgotPasswordModal from './ForgotPasswordModal';

const LoginForm = ({ fields, errors, handleChange, handleLogin, pending, errorMessage, setErrorMessage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFieldChange = (prop) => (event) => {
    setErrorMessage(''); // Очищаем сообщение об ошибке
    handleChange(prop)(event);
  };

 
const [resetEmailOrPhone, setResetEmailOrPhone] = useState(''); 

  return (
    <Box mt={2}>
      <TextField
        label="Логин"
        fullWidth
        variant="outlined"
        value={fields.username}
        onChange={handleFieldChange('username')}
        margin="normal"
        error={!!errors.username}
        helperText={errors.username}
        sx={{ fontSize: '14px' }}
        InputProps={{ sx: { fontSize: '14px' } }}
      />
      <TextField
        label="Пароль"
        type="password"
        fullWidth
        variant="outlined"
        value={fields.password}
        onChange={handleFieldChange('password')}
        margin="normal"
        error={!!errors.password}
        helperText={errors.password}
        sx={{ fontSize: '14px' }}
        InputProps={{ sx: { fontSize: '14px' } }}
      />

      <Box
        paddingTop={0}
        paddingBottom={2}
        onClick={handleOpenModal} 
        sx={{ cursor: 'pointer' }}
      >
        <Typography
          fontSize={12}
          fontWeight={600}
          sx={{
            color: theme.palette.mode === 'dark' ? '#ADD8E6' : '#00008B' // Светло-синий для темной темы и темно-синий для светлой
          }}
        >
          Забыли пароль?
        </Typography>
      </Box>

      <Modal
  open={isModalOpen} // Используем состояние для управления открытием модального окна
  onClose={handleCloseModal}
  aria-labelledby="forgot-password-modal"
  aria-describedby="forgot-password-modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}
  >
    <ForgotPasswordModal 
  forgotPasswordOpen={isModalOpen} 
  setForgotPasswordOpen={setIsModalOpen} 
  resetEmailOrPhone={resetEmailOrPhone} 
  setResetEmailOrPhone={setResetEmailOrPhone} 
/>

  </Box>
</Modal>

      <Box>
        {errorMessage && (
          <Typography color="error" align="center" sx={{ mb: 1 }}>
            {errorMessage}
          </Typography>
        )}
      </Box> 
      
      <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
        <Button
          onClick={handleLogin}
          color="primary"
          fullWidth
          variant="contained"
          sx={{ height: 45, fontSize: '14px' }}
          disabled={pending}
        >
          {pending ? <CircularProgress size={24} /> : 'Войти'}
        </Button>
      </Box>
    </Box>
  );
};

export default LoginForm;
