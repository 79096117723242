import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import CategorySlider from '../components/CategoryPage/CategorySlider';
import NewsSection from '../components/NewsSection';
import EditorialPicks  from '../components/HomePage/EditorialPicks'
import api from '../api';
import { useParams } from 'react-router-dom';

const CategoriesPage = () => {
  const { id } = useParams(); 
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const editorialStoryIds = [9664, 9664, 9664];
  
  useEffect(() => {
    api.get('/api/v1/categories/')
      .then(response => {
        setCategories(response.data);       
        if (!id && response.data.length > 0) {
          setSelectedCategory(response.data[0]); // Передаем объект категории
        } else if (id) {
          const foundCategory = response.data.find(cat => cat.id === parseInt(id, 10));
          setSelectedCategory(foundCategory);
        }
      })
      .catch(error => console.error('Ошибка при загрузке категорий:', error));
  }, [id]);

  const handleSelectCategory = useCallback((categoryId) => {
    const selectedCategory = categories.find(category => category.id === categoryId);
    setSelectedCategory(selectedCategory); // Сохраняем объект категории
  }, [categories]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, pb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <CategorySlider
              categories={categories}
              onSelectCategory={handleSelectCategory}
              selectedCategory={selectedCategory?.id}
            />

            {/* Отображаем имя выбранной категории */}
            <Box sx={{ mt: 4 }}>
              
            </Box>

            {/* Секция с новостями */}
            <NewsSection selectedCategory={selectedCategory} />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          {/* Блок с рекламой и выбором редакции */}
          <Box 
            sx={{
              marginTop: '70px',
              bgcolor: '#f9f9f9',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              p: 2,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              '@media (max-width: 600px)': {
                marginTop: '20px',
                p: 1,
                height: '150px',
              },
            }}
          >
            <Typography 
              variant="h6" 
              sx={{
                fontWeight: 'bold',
                mb: 1,
                '@media (max-width: 600px)': { 
                  fontSize: '1rem',
                  mb: 0.5,
                },
              }}
            >
              Реклама
            </Typography>
            <Box
              component="img"
              src="https://via.placeholder.com/300x150"
              alt="Ad placeholder"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
                '@media (max-width: 600px)': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                },
              }}
            />
          </Box>

          <Box sx={{ mt: 4 }}>
            <EditorialPicks storyIds={editorialStoryIds} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CategoriesPage;