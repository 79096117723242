import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate, useLocation } from 'react-router-dom';

const CategorySlider = ({ categories, onSelectCategory, selectedCategory, localMode = false }) => {
  const [value, setValue] = React.useState(selectedCategory || 0); // Начальное значение из props
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Проверяем, если мы в глобальной области, синхронизируем категорию с URL
  useEffect(() => {
    if (!localMode && categories.length > 0) {
      const urlCategoryId = parseInt(location.pathname.split('/').pop(), 10);
      const initialCategoryIndex = categories.findIndex((category) => category.id === urlCategoryId);
      if (initialCategoryIndex !== -1) {
        setValue(categories[initialCategoryIndex].id);
        onSelectCategory(categories[initialCategoryIndex].id); // Вызываем callback
      }
    }
  }, [categories, location, onSelectCategory, localMode]);

  // Обработчик клика на категорию
  const handleChange = (category) => {
    setValue(category.id); // Меняем локально выбранное значение
    if (!localMode) {
      navigate(`/category/${category.id}`); // Переходим на страницу категории, если глобальный режим
    }
    onSelectCategory(category); // Вызываем callback для изменения категории
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginTop: '16px',
        '@media (max-width: 600px)': {
          marginTop: '8px',
        },
      }}
    >
      <IconButton
        onClick={scrollLeft}
        sx={{
          position: 'absolute',
          left: 0,
          zIndex: 2,
          backgroundColor: isDarkMode ? '#333333' : '#ffffff',
          '&:hover': { backgroundColor: isDarkMode ? '#424242' : '#f0f0f0' },
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          color: isDarkMode ? '#ffffff' : '#000000',
          width: '32px',
          height: '32px',
          '@media (max-width: 600px)': {
            width: '24px',
            height: '24px',
          },
        }}
      >
        <ArrowBackIosIcon sx={{ fontSize: '16px' }} />
      </IconButton>

      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          gap: '12px',
          padding: '0 48px',
          position: 'relative',
          '&::-webkit-scrollbar': { display: 'none' },
          '@media (max-width: 600px)': {
            padding: '0 32px',
          },
        }}
      >
        {categories.map((category) => (
          <Button
            key={category.id}
            onClick={() => handleChange(category)}
            sx={{
              fontWeight: '700',
              textTransform: 'none',
              borderRadius: '50px',
              padding: '0px 10px',
              whiteSpace: 'nowrap',
              flexShrink: 0,
              maxWidth: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              backgroundColor:
                value === category.id
                  ? isDarkMode
                    ? '#424242'
                    : '#f0f0f0'
                  : 'transparent',
              color:
                value === category.id
                  ? isDarkMode
                    ? '#ffffff'
                    : '#000000'
                  : isDarkMode
                    ? '#ffffff'
                    : '#000000',
              border:
                value === category.id
                  ? isDarkMode
                    ? '2px solid #ffffff'
                    : '2px solid #000000'
                  : isDarkMode
                    ? '1px solid #444444'
                    : '1px solid #ebebeb',
              boxShadow:
                value === category.id
                  ? '0px 4px 6px rgba(0, 0, 0, 0.1)'
                  : 'none',
              '&:hover': {
                backgroundColor: isDarkMode ? '#505050' : '#f5f5f5',
              },
              '@media (max-width: 600px)': {
                padding: '5px 10px',
                fontSize: '12px',
                borderRadius: '12px',
                minWidth: '80px',
              },
            }}
          >
            {category.name}
          </Button>
        ))}
      </Box>

      <IconButton
        onClick={scrollRight}
        sx={{
          position: 'absolute',
          right: 0,
          zIndex: 2,
          backgroundColor: isDarkMode ? '#333333' : '#ffffff',
          '&:hover': { backgroundColor: isDarkMode ? '#424242' : '#f0f0f0' },
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          color: isDarkMode ? '#ffffff' : '#000000',
          width: '32px',
          height: '32px',
          '@media (max-width: 600px)': {
            width: '24px',
            height: '24px',
          },
        }}
      >
        <ArrowForwardIosIcon sx={{ fontSize: '16px' }} />
      </IconButton>
    </Box>
  );
};


export default CategorySlider;
