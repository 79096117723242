import React, { useState } from 'react';
import { Dialog, DialogContent, Typography, IconButton, Box, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AuthTabs from './AuthTabs';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import OAuthButtons from './OAuthButtons';
import {  registerUser, loginUser, getUser } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';

const RegistrationForm = ({ isModal, dialogOpen, closeDialog, handleOAuthLogin }) => {
  const { setUser } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [fields, setFields] = useState({
    username: '',
    password: '',
    password2: '',
    email: '',
    firstName: '',
    lastName: ''
  });
  const [errors, setErrors] = useState({});
  const [pending, setPending] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Создаем errorMessage
  const navigate = useNavigate();

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    setIsResetPassword(false);
    setErrors({});
    setStatusMessage('');
    setErrorMessage('');
  };

  const handleClose = () => {
    handleChangeTab(null, 0);
    closeDialog();
  };

  const handleChange = (prop) => (event) => {
    setFields({ ...fields, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: '' }); 
    setErrorMessage('');
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const validateFields = () => {
    let newErrors = {};
    if (!fields.username) newErrors.username = 'Логин обязателен';
    if (!fields.password) newErrors.password = 'Пароль обязателен';
    if (activeTab === 1) {
      if (!fields.email) newErrors.email = 'Email обязателен';
      if (fields.password !== fields.password2) newErrors.password2 = 'Пароли не совпадают';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async () => {
    setErrorMessage(''); // Очищаем сообщение об ошибке перед отправкой
  
    if (!validateFields()) return; // Проверяем корректность полей
  
    setPending(true); // Устанавливаем состояние ожидания
    try {
      await loginUser({
        username: fields.username,
        password: fields.password,
      });
      
      const userData = await getUser(); 
      // Сохраняем пользователя в глобальном состоянии через контекст
      setUser(userData);
      
      // Перенаправляем пользователя в личный кабинет
      navigate('/profile');
      
      // Закрываем диалоговое окно после успешного входа
      if (isModal && closeDialog) closeDialog();
    } catch (error) {
      // Отображаем сообщение об ошибке (например, "Невозможно войти в систему с указанными учётными данными.")
      setErrorMessage(error.message);
    } finally {
      setPending(false); // Сбрасываем состояние ожидания
    }
  }; 
  
  const handleRegister = async () => {
    setErrorMessage('');
    if (!validateFields()) return;
    setPending(true);
    try {
      const response = await registerUser({
        username: fields.username,
        email: fields.email,
        password1: fields.password,
        password2: fields.password2,
        first_name: fields.firstName,
        last_name: fields.lastName
      });
      setUser(response.user);
      navigate('/profile');
      if (isModal && closeDialog) closeDialog();
    } catch (error) {
      processErrorResponse(error);
    } finally {
      setPending(false);
    }
  };

  const handleResetPassword = async () => {
    setPending(true);
    try {
      setStatusMessage('Ссылка на восстановление пароля выслана на почту. Проверьте свой ящик.');
      setIsResetPassword(false);
      setActiveTab(0);
    } catch (error) {
      processErrorResponse(error);
    } finally {
      setPending(false);
    }
  };

  const processErrorResponse = (error) => {
    const serverErrors = error.response?.data || {};
    const errorMessages = Object.keys(serverErrors).reduce((acc, key) => {
      acc[key] = serverErrors[key];
      return acc;
    }, {});
    setErrors(errorMessages);
  };

  const renderFormContent = () => {
    return (
      <>
        <AuthTabs activeTab={activeTab} handleChangeTab={handleChangeTab} />
        {statusMessage && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {statusMessage}
          </Typography>
        )}
        {isResetPassword ? (
          <>
            <IconButton onClick={() => setIsResetPassword(false)}>
              <ArrowCircleLeftIcon />
            </IconButton>
            <Typography variant="h6">Сброс пароля</Typography>
            <TextField
              label="Электронная почта"
              fullWidth
              variant="outlined"
              value={fields.email}
              onChange={handleChange('email')}
              error={!!errors.email}
              helperText={errors.email}
            />
            <Button onClick={handleResetPassword} color="primary" fullWidth>
              Отправить
            </Button>
          </>
        ) : activeTab === 0 ? (
          <LoginForm
            fields={fields}
            errors={errors}
            handleChange={handleChange}
            handleLogin={handleLogin}
            handleForgotPassword={() => setIsResetPassword(true)}
            pending={pending}
            errorMessage={errorMessage} // Передаем errorMessage
            setErrorMessage={setErrorMessage} // Передаем setErrorMessage
          />
        ) : (
          <RegisterForm
            fields={fields}
            errors={errors}
            handleChange={handleChange}
            handleRegister={handleRegister}
            checkboxChecked={checkboxChecked}
            handleCheckboxChange={handleCheckboxChange}
            pending={pending}
          />
        )}
        {activeTab === 0 && <OAuthButtons handleOAuthLogin={handleOAuthLogin} />}
      </>
    );
  };

  if (isModal) {
    return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <DialogContent sx={{ p: 4}}>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>             
        {renderFormContent()}
      </DialogContent>
    </Dialog>
  );
  }

  return (
    <Box sx={{ p: 4, width: '100%', maxWidth: '500px', margin: 'auto' }}>
      {renderFormContent()}
    </Box>
  );

};

export default RegistrationForm;
