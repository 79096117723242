import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme, Skeleton, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import api from '../api';
import NewsCard from './NewsCard';
import TitleWithLineAndDot from './TitleWithLineAndDot';

const NewsSection = ({ type, selectedCategory }) => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const theme = useTheme();  

  const getTitle = () => {
    if (selectedCategory?.name) {
      return selectedCategory.name.trim();
    }

    switch (type) {
      case 'popular':
        return 'Самые популярные новости';
      case 'recommendations':
        return 'Рекомендации для Вас';
      case 'current':
        return 'Текущие новости';
      default:
        return '';
    }  
  };

  useEffect(() => {
    setStories([]);
    setPage(1);
  }, [type, selectedCategory]);

  useEffect(() => {
    const fetchStories = async () => {
      let url = `/api/v1/stories/?page=${page}&page_size=12`;
      
      if (type === 'popular') {
        url += '&type=popular';
      } else if (type === 'current') {
        url = `/api/v1/stories/last/?page_size=12`;
      } else if (type === 'recommendations') {
        url += '&type=recommendations';
      }

      if (selectedCategory?.id) {
        url += `&category=${selectedCategory.id}`;
      }

      setLoading(true);
      try {
        const response = await api.get(url);
        const formattedStories = response.data.results.map((story) => ({
          id: story.id,
          category: story.category,
          creation_at: story.creation_at,
          articles: {
            ...story.news_article,
          },
        }));
  
        setStories((prevStories) => (page === 1 ? formattedStories : [...prevStories, ...formattedStories]));
      } catch (error) {
        console.error('Ошибка при загрузке историй:', error);
      }
      setLoading(false);
    };

    fetchStories();
  }, [type, selectedCategory, page]);

  return (
    <Box>
      <Container sx={{ mb: 2 }}>
        <TitleWithLineAndDot
          title={getTitle()} 
          fontSize="25px"
          lineHeight={6}
          fontWeight={700}
        />
      </Container>

      <Box>
        {loading && page === 1 ? (
          <Grid container spacing={1} sx={{ paddingLeft: 0, paddingRight: 0, justifyContent: { xs: 'center', sm: 'flex-start' } }}>
            {Array.from(new Array(12)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ paddingLeft: 0 }}>
                <Skeleton variant="rectangular" width="100%" height={320} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={1} sx={{ paddingLeft: 0, paddingRight: 0, justifyContent: { xs: 'center', sm: 'flex-start' } }}>
            {stories.slice(0, 12).map((story) => (
              <Grid item xs={12} sm={6} md={4} key={story.id} sx={{ paddingLeft: 0 }}>
                <Link to={`/story/${story.id}`} style={{ textDecoration: 'none' }}>
                  <NewsCard item={story.articles} time={story.creation_at} theme={theme} isLink />
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {!loading && stories.length === 0 && (
        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
          Нет новостей для отображения.
        </Typography>
      )}
    </Box>
  );
};

export default NewsSection;