import React, { useState } from 'react';
import { TextField, Button, Box, CircularProgress, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

const ProfileEditForm = ({ user, onClose, onSave }) => {
  const [fields, setFields] = useState({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    avatar: user.avatar || ''
  });
  const [pending, setPending] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const handleChange = (prop) => (event) => {
    setFields({ ...fields, [prop]: event.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFields({ ...fields, avatar: file });
  };

  const handleSave = async () => {
    setPending(true);

    const formData = new FormData();
    formData.append('first_name', fields.first_name);
    formData.append('last_name', fields.last_name);

    if (fields.avatar instanceof File) {
      formData.append('avatar', fields.avatar);
    }

    try {
     

      // Успешное обновление
      setPending(false);
      setShowDialog(true);
      setDialogMessage('Профиль успешно обновлен');
      
      // Передаём обновленные данные в родительский компонент через onSave
      onSave({
        ...fields,
        avatar: fields.avatar instanceof File ? URL.createObjectURL(fields.avatar) : fields.avatar,  // Убедись, что аватар обновляется
      });
      
    } catch (error) {
      setPending(false);
      console.error('Ошибка при обновлении профиля:', error);
    }
  };

  const handleCancel = () => {
    onClose();  // Закрываем окно при отмене
  };

  const handleSuccessClose = () => {
    setShowDialog(false);
    onClose();  // Закрываем окно после успешного обновления
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Редактировать профиль</DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Avatar src={fields.avatar instanceof File ? URL.createObjectURL(fields.avatar) : fields.avatar} sx={{ width: 100, height: 100, mb: 2 }} />
          <Button component="label">
            Загрузить аватар
            <input type="file" accept="image/*" hidden onChange={handleFileChange} />
          </Button>
        </Box>

        <TextField
          label="Имя"
          fullWidth
          variant="outlined"
          value={fields.first_name}
          onChange={handleChange('first_name')}
          margin="normal"
        />

        <TextField
          label="Фамилия"
          fullWidth
          variant="outlined"
          value={fields.last_name}
          onChange={handleChange('last_name')}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={pending}>
          {pending ? <CircularProgress size={24} /> : 'Сохранить'}
        </Button>
      </DialogActions>

      <Dialog open={showDialog} onClose={handleSuccessClose}>  {/* Закрытие окна после успешного сохранения */}
        <DialogTitle>Успех</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose} color="primary">  {/* Закрытие окна и обновление профиля */}
            ОК
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ProfileEditForm;
