import React, { useState } from 'react';
import { Dialog, DialogContent, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { resetPassword } from '../../../api'; 

const ForgotPasswordModal = ({ forgotPasswordOpen, setForgotPasswordOpen, resetEmailOrPhone, setResetEmailOrPhone }) => {
  const [statusMessage, setStatusMessage] = useState(''); 

  const handleResetPassword = async () => {
    try {
      await resetPassword(resetEmailOrPhone); 
      setStatusMessage('Ссылка на восстановление пароля выслана на почту - проверьте свой ящик.');
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setStatusMessage('Произошла ошибка на сервере. Пожалуйста, попробуйте позже.');
      } else {
        setStatusMessage('Ошибка при отправке запроса. Пожалуйста, проверьте введенные данные.');
      }
    }
  };

  return (
    <Dialog open={forgotPasswordOpen} onClose={() => setForgotPasswordOpen(false)}>
      <DialogContent sx={{ p: 4 }}>
        <Box>
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton onClick={() => setForgotPasswordOpen(false)} sx={{ mr: 1 }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="body1" sx={{ fontSize: '14px' }}>
              Назад
            </Typography>
          </Box>
          
          {statusMessage ? (
            <Typography variant="h6" align="center" sx={{ mb: 2, fontSize: '18px', fontWeight: 'bold' }}>
              {statusMessage}
            </Typography>
          ) : (
            <>
              <Typography variant="h6" align="center" sx={{ mb: 2, fontSize: '18px', fontWeight: 'bold' }}>
                Сброс пароля
              </Typography>
              
              <TextField
                label="Электронная почта"
                fullWidth
                variant="outlined"
                value={resetEmailOrPhone}
                onChange={(e) => setResetEmailOrPhone(e.target.value)}
                margin="normal"
                InputProps={{ sx: { fontSize: '14px' } }}
              />
              
              <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
                <Button
                  onClick={handleResetPassword}
                  color="primary"
                  variant="contained"
                  sx={{ width: '100%', height: 45, fontSize: '14px' }}
                >
                  Отправить
                </Button>            
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordModal;
