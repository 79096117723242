import React, { useState } from 'react';
import { Box, Typography, Button, Card, CardContent, Avatar } from '@mui/material';
import { useTheme } from '@mui/material';
import DynamicTimeDisplay from '../DynamicTimeDisplay'; // Если есть отдельный компонент для времени

const RelatedArticles = ({ articles, onArticleClick, selectedArticle }) => {
  const filteredArticles = articles.filter(article => article.id !== selectedArticle.id);
  
  const theme = useTheme();
  const [visibleCount, setVisibleCount] = useState(5); // Отображать по 5 статей

  
  // Функция для показа еще 5 статей
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  return (
    <Box sx={{ mt: 0 }}>
      {filteredArticles.length > 0 && (
        <Typography 
          variant="h6" 
          sx={{ ml: 1, fontWeight: 'bold', fontSize: '16px' }}
        >
          ЭТОТ СЮЖЕТ В СМИ:
        </Typography>
      )}
  
      {filteredArticles.slice(0, visibleCount).map((article, index) => (
        <Card 
          key={index} 
          onClick={() => onArticleClick(article)} 
          sx={{    
            margin: '5px', 
            padding: '5px',
            border: '1px solid #80808011', 
            borderRadius: '10px',
            cursor: 'pointer', 
            transition: 'transform 0.2s, background-color 0.2s', 
            backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff', 
            '&:hover': { 
              transform: 'scale(1.02)',
              backgroundColor: theme.palette.mode === 'dark' ? '#616161' : '#f5f5f5', 
            },    
          }}
        >
          <CardContent 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start', 
              padding: '0px', 
              '&:last-child': { paddingBottom: '0px' } 
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
              <Avatar 
                src={article.source.favicon} 
                alt={`Source ${index + 1} Logo`} 
                sx={{ mr: 1, width: 20, height: 20, border: '1px solid gray' }} 
              />
              <Typography 
                variant="body2" 
                fontWeight={500} 
                sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000', marginRight: '8px' }}
              >
                {article.source.name}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ color: theme.palette.mode === 'dark' ? '#bdbdbd' : 'gray' }}
              >
                <DynamicTimeDisplay creationDate={article.publication_at} />
              </Typography>
            </Box>
            <Typography 
              variant="body1" 
              fontWeight={600} 
              sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000', fontSize: '12px' }}
            >
              {article.title}
            </Typography>
          </CardContent>
        </Card>
      ))}
  
      {visibleCount < filteredArticles.length && (
        <Button 
          onClick={handleShowMore} 
          variant="outlined" 
          sx={{ mt: 1, display: 'block', mx: 'auto' }}
        >
          +5 показать
        </Button>
      )}
    </Box>
  );
};

export default RelatedArticles;
