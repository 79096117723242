import React from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import NewsSection from '../components/NewsSection';
import CategoryNewsList from '../components/HomePage/CategoryNewsList';
import EditorialPicks from '../components/HomePage/EditorialPicks';
import useMediaQuery from '@mui/material/useMediaQuery'; 
import { useTheme } from '@mui/material/styles';

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

 
  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}> {/* Уменьшил верхний отступ */}
      <Grid container spacing={2}> {/* Уменьшил spacing */}
        
        <Grid item xs={12} md={9}>
          <CategoryNewsList />
        </Grid>

        <Grid item xs={12} md={3}>
        <Box 
  sx={{
    marginTop: '70px',
    bgcolor: '#f9f9f9',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    p: 2,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    '@media (max-width: 600px)': { // Адаптируем для экранов меньше 600px
      marginTop: '20px', // Уменьшаем верхний отступ
      p: 1, // Уменьшаем внутренние отступы
      height: '150px', // Фиксируем высоту на 150px для мобильных устройств
    },
  }}
>
  <Typography 
    variant="h6" 
    sx={{
      fontWeight: 'bold',
      mb: 1,
      '@media (max-width: 600px)': { 
        fontSize: '1rem', // Уменьшаем размер шрифта на мобильных устройствах
        mb: 0.5, // Уменьшаем нижний отступ для текста
      },
    }}
  >
    Реклама
  </Typography>
  <Box
    component="img"
    src="https://via.placeholder.com/300x150"
    alt="Ad placeholder"
    sx={{
      width: '100%',
      height: 'auto', // Сохраняем пропорции изображения
      borderRadius: '8px',
      '@media (max-width: 600px)': {
        width: '100%',
        height: '100%', // Занимает всю высоту родительского блока (150px)
        objectFit: 'cover', // Изображение покрывает весь блок, сохраняя пропорции
      },
    }}
  />
</Box>



</Grid>

        <Grid item xs={12} md={9}>
          <Box sx={{ mb: 2 }}> {/* Уменьшил отступ снизу */}
            <NewsSection type="popular" />
          </Box>
          <Box sx={{ mb: 2, mt: 5 }}> {/* Уменьшил отступ снизу */}
            <NewsSection type="recommendations" />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Box 
            sx={{ 
              display: isMobile ? 'none' : 'block',
              top: '20px',
              overflow: 'visible'
            }}
          >
            <EditorialPicks />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
