import React, { useState, useEffect } from 'react';
import { Box, Grid, CircularProgress, Button, Typography, Avatar, Container } from '@mui/material';
import UserRecommendations from './UserRecommendations';
import ProfileEditForm from './ProfileEditForm';
import EditorialPicks from '../../components/HomePage/EditorialPicks';
import CategoryNewsList from '../HomePage/CategoryNewsList';
import { useUser } from '../../context/UserContext';
import { getUser } from '../../api'; // Импорт функции API для получения пользователя

const ProfilePage = () => {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser();
        setUser(userData);  // Обновляем данные пользователя
      } catch (error) {
        console.error('Ошибка получения данных пользователя:', error);
      } finally {
        setLoading(false);  // Отключаем индикатор загрузки
      }
    };
  
    if (loading) {
      fetchUser();
    }
  }, [loading, setUser]);

  const handleEditProfile = () => {
    setProfileMenuOpen(true);
  };

  const handleSaveProfile = (updatedFields) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedFields,
      avatar: updatedFields.avatar instanceof File ? URL.createObjectURL(updatedFields.avatar) : updatedFields.avatar,
    }));
  };

  const handleCloseEditModal = () => {
    setProfileMenuOpen(false); // Закрываем модальное окно
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {/* Левая колонка (Новости и Рекомендации) */}
          <Grid item xs={12} md={9}>
            {/* Новости */}
            <Box sx={{ mb: 4 }}>
              <CategoryNewsList />
            </Box>
            {/* Рекомендации для вас */}
            <Box sx={{ mb: 4 }}>
              <UserRecommendations />
            </Box>
          </Grid>

          {/* Правая колонка с информацией о пользователе и выбором редакции */}
          <Grid item xs={12} md={3}>
            <Box 
              sx={{
                marginTop: '20px',
                bgcolor: '#f9f9f9',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                p: 2,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Информация о пользователе */}
              {user && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    mb: 4,
                    width: '100%',
                  }}
                >
                  <Avatar
                    src={user.avatar || 'https://via.placeholder.com/150'}
                    alt={user.username}
                    sx={{ width: 100, height: 100, mb: 2 }}
                  />
                  <Typography variant="h6">{`${user.first_name} ${user.last_name}`}</Typography>
                  <Typography variant="body2" color="textSecondary">{user.email}</Typography>
                  <Button variant="contained" sx={{ mt: 2 }} onClick={handleEditProfile}>
                    Редактировать
                  </Button>
                </Box>
              )}
            </Box>

            {/* Выбор редакции */}
            <Box 
              sx={{
               
                borderRadius: '8px',               
                mt: 4,
                
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  mb: 1,
                }}
              >
               
              </Typography>
              <EditorialPicks />
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Модальное окно для редактирования профиля */}
      {isProfileMenuOpen && (
        <ProfileEditForm
          user={user}
          onClose={handleCloseEditModal}
          onSave={handleSaveProfile}
        />
      )}
    </Box>
  );
};

export default ProfilePage;
